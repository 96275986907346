<template>
  <div>
    <deck-display></deck-display>
  </div>
</template>

<script>
import DeckDisplay from '@/components/DeckDisplay.vue'
import presets from '@/helpers/presets'
import { buildDeckFromPreset } from '@/helpers/deckHelper'

// TODO 404 Error page
export default {
  watch: {
    $route(to) {
      const id = to.params.id
      this.showDeck(id)
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.showDeck(id)
  },
  methods: {
    showDeck(id) {
      const deck = presets.find( x => x.id == id )
      if (deck) {
        let deckToShow = buildDeckFromPreset(deck)
        this.$store.commit('setCurrentDeck', deckToShow)
      }
    }
  },
  components: {
    DeckDisplay,
  },
}
</script>
