<template>
  <div class="ml-4">
    <button class="flex items-center" type="button" @click="open = !open">
      Zusammengestellte Decks
      <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
    </button>
    <div class="absolute z-30 px-4 pt-4 mt-5 bg-white rounded shadow-lg" v-if="open">
      <div v-for="deck in presets" :key="deck.id">
        <router-link :to="{name: 'preset', params: {id: deck.id}}" class="flex items-center mb-4 whitespace-nowrap" @click.native="close()">
          <div class="w-10 mr-2">
            <deck-image :deck="deck"></deck-image>
          </div>
          <div>
            <div class="text-base text-gray-800">
              {{ deck.name }}
            </div>
            <div class="text-xs text-gray-500">
              {{ deck.card_ids.length }} Karten
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import presets from '@/helpers/presets'
import DeckImage from '@/components/DeckImage'

export default {
  components: {
    DeckImage
  },
  data() {
    return {
      presets: presets,
      open: false
    }
  },
  methods: {
    close() {
      this.open = false
    }
  },
}
</script>
