<template>
  <div class="relative">
    <button class="flex items-center text-sm text-gray-400" type="button" v-click-outside="close" @click="open = !open">
      <div class="relative mr-2 transition-scale transform duration-200" :class="{'scale-125': animating}">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        <div class="absolute top-0 right-0 z-10 -mt-2 -mr-3">
          <badge :count="count"></badge>
        </div>
      </div>
      <span class="hidden sm:inline">Warenkorb</span>
    </button>
    <div class="absolute right-0 z-30 w-screen p-2 mt-5 -mr-4 bg-gray-100 border rounded shadow-lg xs:p-4 xs:w-auto xs:mr-0" v-if="open">
      <cart-item v-for="deck in $store.getters.decksInCart" :key="deck.id" :deck="deck" v-on:close="open = false"></cart-item>
      <div v-if="$store.getters.decksInCartCount">
        <div class="flex justify-between pt-3 mt-3 font-bold text-gray-700 border-t">
          <div>
            Zwischensumme:
            <div class="text-xs font-normal text-gray-600">zzgl. Versandkosten</div>
          </div>
          <div class="text-lg">
            {{ total | euro }}
          </div>
        </div>
        <div class="mt-6">
          <router-link to="/checkout" class="block w-full py-2 font-medium text-center text-white bg-red-800 shadow-md rounded-md hover:bg-red-900" @click.native="close">
            Zur Kasse
          </router-link>
        </div>
      </div>
      <div v-else class="text-gray-600 whitespace-nowrap">
        Dein Warenkorb ist leer
      </div>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/Badge'
import CartItem from '@/components/CartItem'
import ClickOutside from 'vue-click-outside'
import {getTotalPrice} from '@/helpers/deckHelper'

export default {
  components: {
    Badge,
    CartItem
  },
  data() {
    return {
      open: false,
      animating: false
    }
  },
  watch: {
    count () {
      if (!this.open) {
        this.animating = true
        setTimeout(() => {
          this.animating = false
        },200)
      }
    }
  },
  methods: {
    close() {
      this.open = false
    }
  },
  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  // do not forget this section
  directives: {
    ClickOutside
  },
  computed: {
    count() {
      return this.$store.getters.decksInCartCount
    },
    total() {
      return getTotalPrice(this.$store.getters.decksInCart)
    }
  }
}
</script>
