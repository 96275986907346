import data from './cards.json'

export default function getCards() {
  var cards = data.cards.filter(
    card => (
      card.title != ''
      && card.type!=""
      && card.card_id != 421 // Andere Seite
      && card.card_id != 573 // irgendsoeine Zahlenfolge
      && card.type!="Belohnung"
      && card.title!="Changelog"
    )
  )
  return cards
}
