<template>
  <div class="mx-6 mt-24 w-72">
    <router-link :to="{name: 'preset', params: {id: deck.id}}" class="flex flex-col h-full group">
      <div class="relative z-10 pt-44 transform-gpu transition-all duration-300 ease-out group-hover:scale-105">
        <div class="absolute top-0 left-0 w-32 transform-gpu transition-all duration-300 origin-bottom-left"
             v-for="(image, index) in teaserImages" :key="image"
             :class="imgClass(index)"
             >
           <picture class="relative transform" :class="{'scale-150 z-10 rounded': index==5}">
             <source :data-srcset="`https://cards.incantata.de/cdn/avif_medium/card_${image}.avif`" type="image/avif" :srcset="`https://cards.incantata.de/cdn/avif_medium/card_${image}.avif`">
             <img class="rounded-md " loading="lazy" :src="`https://cards.incantata.de/cdn/medium/card_${image}.jpg`" :data-src="`https://cards.incantata.de/cdn/medium/card_${image}.jpg`">
           </picture>
        </div>
      </div>
        <div class="relative flex-grow">
          <div class="absolute top-0 right-0 z-10 -mt-4 -mr-4 sm:-mt-7 sm:-mr-7">
            <svg width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="a" fill="#fff"><path fill-rule="evenodd" clip-rule="evenodd" d="M30.09 58.994C46.11 58.68 59 45.595 59 29.5 59 13.208 45.792 0 29.5 0 13.802 0 .968 12.262.052 27.73H0V59h30.09v-.006z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M30.09 58.994C46.11 58.68 59 45.595 59 29.5 59 13.208 45.792 0 29.5 0 13.802 0 .968 12.262.052 27.73H0V59h30.09v-.006z" fill="#fff"/><path d="M30.09 58.994l-.059-3-2.941.059v2.941h3zM.052 27.73v3H2.88l.167-2.823-2.995-.177zm-.052 0v-3h-3v3h3zM0 59h-3v3h3v-3zm30.09 0v3h3v-3h-3zM56 29.5c0 14.458-11.58 26.212-25.969 26.495l.118 5.999C47.799 61.647 62 47.232 62 29.5h-6zM29.5 3C44.136 3 56 14.864 56 29.5h6C62 11.55 47.45-3 29.5-3v6zM3.047 27.907C3.869 14.015 15.399 3 29.5 3v-6C12.204-3-1.934 10.509-2.943 27.553l5.99.354zM0 30.73h.052v-6H0v6zm3-1.23v-1.77h-6v1.77h6zM3 59V29.5h-6V59h6zm26.5-3H0v6h29.5v-6zm.59 0h-.59v6h.59v-6zm-3 2.994V59h6v-.006h-6z" fill="#600000" mask="url(#a)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M29.99 53.995C43.295 53.735 54 42.867 54 29.5 54 15.969 43.031 5 29.5 5 16.463 5 5.804 15.183 5.043 28.03H5V54h24.99v-.005z" fill="#600000"/></svg>
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="text-gray-200">
                {{ deck.price | euro }}
              </div>
            </div>
          </div>
          <div class="absolute inset-0 bg-black rounded-lg opacity-70 group-hover:ring-pink-800 ring-2 ring-transparent"></div>
          <div class="relative flex flex-col h-full p-6">
            <h2 class="text-xl font-extrabold text-white">
              {{ deck.name }}
            </h2>
            <div class="text-xs text-gray-300">
              {{ deck.card_ids.length }} Karten
            </div>
            <div class="flex-grow mt-4 text-sm text-gray-300">
              {{ deck.teaser }}
            </div>
            <div class="py-2 mt-4 font-bold text-center text-white bg-red-900 rounded-lg shadow-md group-hover:bg-red-800">
              Details
            </div>
          </div>
        </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: [
    'deck'
  ],
  computed: {
    teaserImages() {
      return this.deck.teaser_card_ids.slice(0,4).reverse()
    }
  },
  methods: {
    imgClass(index) {
      let css = ''
      switch(index) {
        case 0:
          css = '-rotate-12 group-hover:-rotate-24 translate-x-16'
          break;
        case 1:
          css = '-rotate-6 group-hover:-rotate-12 translate-x-20'
          break;
        case 2:
          css =  'rotate-0 translate-x-24'
          break
        case 3:
          css=  'rotate-6 group-hover:rotate-12 translate-x-28'
          break
      }
      return css
    }
  }
}
</script>
