import Vue from 'vue'
import Vuex from 'vuex'     // global storage to exchange between components

Vue.use(Vuex)

let decks = window.localStorage.getItem('decks')

export default new Vuex.Store({
  state: {
    currentDeck: {},
    decks: decks ? JSON.parse(decks) : [],
  },

  mutations: {
    setCurrentDeck(state, deck) {
      state.currentDeck = deck
    },
    addToCart(state) {
      // is current Deck a preset that is already in decks?
      let deck = state.decks.find( x => x.id == state.currentDeck.id )
      if (deck) {
        deck.in_cart += 1
      } else {
        // needed so that vue knows in_cart
        Vue.set(state.currentDeck, 'in_cart',  1)
        state.decks.push(state.currentDeck)
      }
      this.commit('saveDecks')
    },
    deleteDeck(state, deck) {
      let index = state.decks.indexOf(deck)
      if (index > -1) {
        state.decks.splice(index, 1)
        this.commit('saveDecks')
      }
    },
    saveDecks(state) {
      window.localStorage.setItem('decks', JSON.stringify(state.decks));
    }
  },

  getters: {
    decksInCart: state => {
      return state.decks.filter( x => x.in_cart > 0 )
    },
    decksInCartCount: state => {
      let count = 0
      state.decks.forEach( x => count += x.in_cart )
      return Math.max(0, count)
    }
  }

})
