<template>
  <div class="flex items-center mb-4 sm:whitespace-nowrap">
    <div class="w-8 sm:w-10">
      <deck-image :deck="deck"></deck-image>
    </div>
    <button @click="decrement" class="block p-3 pr-1">
      <div class="text-gray-600 bg-white border">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path></svg>
      </div>
    </button>
    <div class="w-6 text-xs text-center text-gray-800 sm:text-base">
      {{ deck.in_cart }}
    </div>
    <button @click="increment" class="block p-3 pl-1">
      <div class="text-gray-600 bg-white border">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
      </div>
    </button>
    <div class="flex-grow mr-2 sm:mr-6">
      <router-link :to="{name: 'preset', params: {id: deck.id}}" @click.native="$emit('close')" class="flex flex-col">
        <span class="text-sm sm:text-base">
          {{ deck.name }}
        </span>
        <span class="text-xs text-gray-500">
          {{ totalCards }} Karten
        </span>
      </router-link>
    </div>
    <div class="w-16 font-bold text-right whitespace-nowrap">{{ totalPrice | euro }}</div>

  </div>
</template>

<script>
import DeckImage from '@/components/DeckImage'
import { sumCards } from '@/helpers/deckHelper'

export default {
  props: [
    'deck',
  ],
  components: {
    DeckImage,
  },
  computed: {
    totalPrice() {
      return this.deck.price * this.deck.in_cart
    },
    totalCards() {
      return sumCards(this.deck)
    }
  },
  methods: {
    increment() {
      this.deck.in_cart ++
    },
    decrement() {
      this.deck.in_cart --
      if(this.deck.in_cart == 0) this.$store.commit('deleteDeck', this.deck)
    },
  }
}
</script>
