<template>
  <div class="relative" v-if="$store.state.currentDeck.cards">

    <div class="flex items-center justify-between">
      <div class="text-xl font-extrabold text-white sm:text-3xl lg:text-4xl" v-text="$store.state.currentDeck.name"> </div>

      <div class="flex flex-col items-center sm:flex-row sm:pt-0">
        <div class="text-base font-medium text-white sm:mr-3 sm:ml-4 sm:text-xl">
          {{ $store.state.currentDeck.price | euro }}
        </div>
        <button @click="addToCart()" type="button" class="px-2 py-1 text-xs font-medium text-white bg-red-800 sm:px-4 sm:py-2 rounded-md hover:bg-red-900 sm:text-base">
          Bestellen
        </button>
      </div>
    </div>

    <div class="flex-row-reverse items-start justify-between mt-4 sm:mt-6 sm:flex">
      <div class="mb-4 text-gray-100 sm:text-right whitespace-nowrap">
        <div class="text-sm sm:text-base">{{ totalCards }} Karten</div>
        <div class="text-xs">{{ differentCards }} verschieden Karten</div>
        <div class="flex flex-wrap justify-start mt-4 sm:justify-end">
          <mana-badge v-for="color in $store.state.currentDeck.colors" :key="color" :color="color" />
        </div>
      </div>
      <div class="max-w-xl text-sm text-gray-200 richtext sm:text-base">
        <p>{{$store.state.currentDeck.flavour}}</p>
      </div>
    </div>

  </div>
</template>

<script>
import { sumCards } from '@/helpers/deckHelper'
import ManaBadge from '@/components/ManaBadge'

export default {
  components: {
    ManaBadge
  },
  methods: {
    addToCart() {
      this.$store.commit('addToCart')
    }
  },
  computed: {
    differentCards() {
      if (this.$store.state.currentDeck.cards) return this.$store.state.currentDeck.cards.length
      return 0
    },
    totalCards() {
      return sumCards(this.$store.state.currentDeck)
    }
  }
}
</script>
