<template>
  <div class="inline-flex items-center px-2 py-1 mt-2 ml-2 rounded-full" :class="css">
    <img class="w-4" :src="`/img/mana_${color}.png`">
    <div class="ml-1 text-xs"> {{name}} </div>
  </div>
</template>

<script>
  const colors = {
    'green' : {name: 'grün', css: 'bg-green-200 text-green-800'},
    'red'   : {name: 'rot', css: 'bg-red-200 text-red-800'},
    'blue'  : {name: 'blau', css: 'bg-blue-200 text-blue-800'},
    'black' : {name: 'schwarz', css: 'bg-gray-800 text-yellow-50'},
    'white' : {name: 'weiß', css: 'bg-yellow-50 text-black'},
  }
  export default {
    props: [
      'color'
    ],
    computed: {
      css() {
        return colors[this.color].css
      },
      name() {
        return colors[this.color].name
      }
    }
  }
</script>
