<template>
  <div class="relative mx-auto">
    <div class="pb-1 text-2xl font-extrabold text-center">
      <span class="text-white">Deck </span>
      <span class="tracking-wider text-red-800 text-shadow">Builder</span>
    </div>

    <div class="p-1 mx-auto bg-white shadow-lg rounded-md w-72">
      <div class="h-2 mb-1 mr-6 bg-gray-300 rounded">
      </div>
      <a href="https://deck-builder.incantata.de/cards.html#import&prepared_deck_id=6">
        <div class="flex flex-wrap ">
          <div class="w-1/4" v-for="(image, index) in randomCards" :key="image">
            <picture>
              <source class="relative transform" :class="{'scale-150 z-10 rounded': index==5}" :data-srcset="`https://cards.incantata.de/cdn/avif_lq/card_${image}.avif`" type="image/avif" :srcset="`https://cards.incantata.de/cdn/avif_lq/card_${image}.avif`">
              <img class="relative transform" :class="{'scale-150 z-10 rounded': index==5}" :src="`https://cards.incantata.de/cdn/lq/card_${image}.jpg`" :data-src="`https://cards.incantata.de/cdn/lq/card_${image}.jpg`">
            </picture>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import presets from '@/helpers/presets'
export default {
  computed: {
    randomCards() {
      let fullSet = presets.find( x => x.id == 7 )
      return fullSet.teaser_card_ids.splice(0,8)
    }
  }

}
</script>
