<template>
  <div class="max-w-xl p-4 mx-auto bg-gray-100 border shadow-lg sm:p-6 sm:mt-10">
    <h1 class="mb-6 text-xl text-center sm:text-2xl sm:mb-10">Bestellung</h1>
    <div v-if="success">
      <p v-html="successMessage"></p>
    </div>
    <div v-if="!success && $store.getters.decksInCartCount">
      <cart-item v-for="deck in $store.getters.decksInCart" :key="deck.id" :deck="deck"></cart-item>
      <div class="flex justify-between pt-3 mt-3 font-bold text-gray-700 border-t">
        <div>
          Gesamtsumme <span class="whitespace-nowrap">(incl. 19%</span> MwSt.):
          <div class="text-xs font-normal text-gray-600">zzgl. Versandkosten</div>
        </div>
        <div class="text-lg">
          {{ total | euro }}
        </div>
      </div>
      <form @submit.prevent="processForm" class="mt-4">
        <div class="space-y-4">
          <div>
            <label for="name" class="custom-form-label">
              Dein Name
            </label>
            <div class="mt-1">
              <input id="name" v-model="name" type="text" required class="custom-form-field">
            </div>
          </div>

          <div>
            <label for="address" class="custom-form-label">
              Lieferadresse
            </label>
            <div class="mt-1">
              <textarea id="address" v-model="address" required class="custom-form-field"></textarea>
            </div>
          </div>

          <div>
            <label for="email" class="custom-form-label">
              E-Mail
            </label>
            <div class="mt-1">
              <input id="email" v-model="email" type="email" autocomplete="email" required class="custom-form-field">
            </div>
          </div>

          <div>
            <label for="payment" class="custom-form-label">
              Bezahlung
            </label>
            <div class="mt-1">
              <label for="payment_paypal" class="mr-6 cursor-pointer whitespace-nowrap">
                <input id="payment_paypal" v-model="payment" type="radio" class="mr-1" value="paypal" required name="payment">
                <span class="text-sm sm:text-base">PayPal</span>
              </label>
              <label for="payment_bar" class="mr-6 cursor-pointer whitespace-nowrap">
                <input id="payment_bar" v-model="payment" type="radio" class="mr-1" value="bar" required name="payment">
                <span class="text-sm sm:text-base">Bar</span>
              </label>
              <label for="payment_bitcoin" class="mr-6 cursor-pointer whitespace-nowrap">
                <input id="payment_bitcoin" v-model="payment" type="radio" class="mr-1" value="bitcoin" required name="payment">
                <span class="text-sm sm:text-base">Bitcoin</span>
              </label>
              <label for="payment_obyte" class="mr-6 cursor-pointer whitespace-nowrap">
                <input id="payment_obyte" v-model="payment" type="radio" class="mr-1" value="obyte" required name="payment">
                <span class="text-sm sm:text-base">Obyte</span>
              </label>
            </div>
          </div>

          <div>
            <label class="custom-form-label">
              Versand
            </label>
            <div class="mt-1">
              <label for="shipping_dhl" class="mr-6 cursor-pointer whitespace-nowrap">
                <input id="shipping_dhl" v-model="shipping" type="radio" class="mr-1" value="dhl" required name="shipping">
                <span class="text-sm sm:text-base">DHL (4,90€)</span>
              </label>
              <label for="shipping_self" class="mr-6 cursor-pointer whitespace-nowrap">
                <input id="shipping_self" v-model="shipping" type="radio" class="mr-1" value="self" required name="shipping">
                <span class="text-sm sm:text-base">Abholung in Kiel</span>
              </label>
            </div>
          </div>

          <div>
            <label for="payment" class="custom-form-label">
              Lieferdauer
            </label>
            <div class="mt-1">
              Da die Karten erst in Druck gehen können, wenn genügend Bestellungen eingegangen sind, ist die Lieferdauer z.Z bis zu 9 Wochen.
            </div>
          </div>

          <div>
            <label for="message" class="custom-form-label">
              Nachricht
            </label>
            <div class="mt-1">
              <textarea v-model="message" id="messsage" class="custom-form-field"></textarea>
            </div>
          </div>

          <alert-box v-if="errorMessage" header="Fehler beim Versenden" :message="errorMessage"></alert-box>

          <div class="">
            <button type="submit" :disabled="processing" :class="{'opacity-50 cursor-not-allowed': processing}" class="flex justify-center w-full px-4 py-2 mt-10 text-sm font-medium text-white bg-red-800 border border-transparent rounded-md shadow-sm hover:bg-red-900 ">
              <span v-if="processing">Bestellung senden ...</span>
              <span v-else>Bestellung abschicken</span>
            </button>
          </div>

        </div>
      </form>
    </div>
    <div v-else class="text-center">
      <div v-if="!success">
        <alert-box header="Keine Decks im Warenkorb." variant="warning"></alert-box>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from '@/components/CartItem'
import AlertBox from '@/components/AlertBox'
import { getTotalPrice, toString } from '@/helpers/deckHelper'

export default {
  components: {
    AlertBox,
    CartItem
  },
  mounted() {
    this.success = false
    // clear messages in form
    this.message = ''
    this.errorMessage = ''
    this.successMessage = ''
  },
  data() {
    return {
      processing: false,
      success: true,
      errorMessage: '',
      successMessage: '',
      name: '',
      address: '',
      email: '',
      message: '',
      shipping: 'dhl',
      payment: 'paypal',
    }
  },
  computed: {
    total() {
      return getTotalPrice(this.$store.getters.decksInCart)
    }
  },
  methods: {
    processForm() {
      this.processing = true

      var formData = {
        name: this.name,
        address: this.address,
        email: this.email,
        message: this.message,
        shipping: this.shipping,
        payment: this.payment,
        total: this.total,
      }
      // decks
      var decks = this.$store.getters.decksInCart
      decks.forEach((x) => {
        x.total = x.price * x.in_cart
        x.ids = toString(x)
      })
      formData.decks = decks

      console.log(formData)

      const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      };
      // fetch('http://localhost:8081/order.php', requestOptions)
      fetch('https://cards.incantata.de/order.php', requestOptions)
        .then(async response => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          // response was ok
          this.errorMessage = ''
          this.processing = false
          // clear cart
          this.$store.getters.decksInCart.forEach((deck) => {
            this.$store.commit('deleteDeck', deck)
          })

          // show returned success message
          this.successMessage=data.message

          // show thankyou
          this.success = true
          console.log(data)
        })
        .catch(error => {
          this.errorMessage = error;
          this.processing = false
          console.error('There was an error!', error);
        });
    }
  }
}
</script>
