import getCards from './cards'


export function sumCards(deck) {
  let sum = 0
  if (deck.cards) {
    deck.cards.forEach( (x) => {
      sum += x.quantity
    })
  }
  return sum
}

export function toString(deck) {
  var currentDeck = deck
  var card_ids = []
  for (var i in currentDeck.cards){
    var currentCard=currentDeck.cards[i]
    var quantity=currentCard.quantity
    for(var c=0;c<quantity;c++){
      card_ids.push(currentCard.card_id)
    }
  }
  return card_ids.sort((a, b) => a - b).join(',')
}
/**
 * sums up all prices of given decks in cart
 * @param  {Array of Objects} decks
 * @return {integer}       Price total
 */
export function getTotalPrice(decks){
  let sum = 0
  decks.forEach((x) => {
    sum += x.in_cart * x.price
  })
  return sum
}

export function buildDeckFromPreset(deck) {
  const constructedDeck = constructDeck(deck.card_ids, deck.name, deck.price, deck.cellar)
  const newDeck = {...constructedDeck, ...deck} // splat operator: adds all properties from deck to constructedDeck
  return newDeck
}

/**
 * constructs a new deck
 * @param  {Array} card_ids   ids
 * @param  {string} name       name for deck
 * @param  {Number} [price=24] price for one deck
 * @return {Object}            one deck of cards
 */
export function constructDeck(card_ids, name, price = 24, cellar = 0) {
  if(name == undefined || name == '') name = 'Custom Set'
  var deck = { name: name, price: price, cards: [] }
  var is_in_cellar = false
  card_ids.forEach( function(card_id, index){
      is_in_cellar = (index >= card_ids.length - cellar)
      addCardToDeck(deck, card_id, is_in_cellar)
  })
  return deck
}

function addCardToDeck(deck, card_id, is_in_cellar = false) {
  var index = deck.cards.findIndex(( x => x.card_id == card_id ))
  if (index >= 0 && deck.cards[index].cellar == is_in_cellar) {
    deck.cards[index].quantity ++
  } else {
    // find in all cards
    var allCards = getCards()
    var card = allCards.find( x => x.card_id == card_id)
    if (card) {
      deck.cards.push({card_id: card_id, title: card.title, quantity: 1, cellar: is_in_cellar})
    }
  }
}
