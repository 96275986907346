import Vue from 'vue'       // framework
import App from './App.vue' // the main website

import './assets/css/index.css'

import store from './store'
import router from './router'

import "./helpers/filters"

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
