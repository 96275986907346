<template>
  <div class="relative">

    <div class="absolute inset-x-0 ">
      <img :src="deckBackground()" class="object-cover w-screen" alt="">

      <div class="absolute inset-0" style="background: linear-gradient(180deg, rgba(17,24,39,0.4) 0, rgba(17,24,39,1) 100%)"> </div>
    </div>

    <div class="p-4 sm:p-6 lg:p-8 xl:p-12 ">
      <deck-tool-bar></deck-tool-bar>
      <div class="flex flex-wrap mx-auto -mx-4">
        <card
          class="p-4"
          v-for="card in cards"
          :key="card.card_id"
          :card="card"
          />
      </div>
    </div>
    <div v-if="cellar" class="p-4 sm:p-6 lg:p-8 xl:p-12  mt-10 bg-gray-800">
      <div class="text-white font-extrabold text-lg sm:text-2xl lg:text-3xl mb-6">
        Bibliothekskeller
      </div>
      <div class="flex flex-wrap mx-auto -mx-4">
        <card
          class="p-4"
          v-for="card in cellar"
          :key="card.card_id"
          :card="card"
          />
      </div>
    </div>
  </div>

</template>

<script>

import Card from '@/components/Card.vue'
import DeckToolBar from '@/components/DeckToolBar.vue'

export default {
  components: {
    Card,
    DeckToolBar,
  },
  methods: {
    deckBackground() {
      if (this.$store.state.currentDeck.id) {
        return `/img/bg-preset-${this.$store.state.currentDeck.id}.jpg`
      }
    }
  },
  computed: {
    cards() {
      let cards = this.$store.state.currentDeck.cards
      if (this.$store.state.currentDeck.cellar) {
        cards = cards.filter(card => {
          return card.cellar == false
        })
      }
      if (this.$store.state.currentDeck.id >= 6) return cards.sort((a, b) => { return b.quantity - a.quantity;}) // sort all cards by quantity
      else return cards
    },
    cellar() {
      if (this.$store.state.currentDeck.cellar) {
        return this.$store.state.currentDeck.cards.filter(card => {
          return card.cellar == true
        })
      } else {
        return false
      }
    }
  }
}
</script>
