<template>
  <img :src="imageUrl" loading="lazy">
</template>

<script>
export default {
  props: [
    'deck'
  ],
  computed: {
    imageUrl() {
      let teaserIds = this.deck.teaser_card_ids
      let teaserId
      if (teaserIds) {
        teaserId = teaserIds[0]
      } else {
        teaserId = this.deck.cards[0].card_id
      }
      return `/img/s_cards/card_${teaserId}.jpg`
    }
  }
}
</script>
