<template>
  <div>

    <div class="bg-cover" style="background-image: url(img/background2.jpg)">

      <div class="px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8 ">

        <div class="pt-8 sm:pt-16">
          <h1 class="text-3xl font-extrabold text-white mt- sm:text-4xl lg:text-5xl">
            Incantata
          </h1>
        </div>
        <div class="items-start justify-between sm:flex">
          <div class="sm:pr-10">
            <p class="pb-2 mt-3 text-xl text-white sm:text-2xl md:text-3xl">
            Das mysteriös aufgetauchte Fantasy-Sammelkartenspiel.
            </p>
            <div class="max-w-xl">
              <p class="mt-3 text-sm text-gray-300 sm:mt-5 sm:text-base">
              Werde Teil einer großartigen Community, Wähle dein Schicksal aus einer der fünf Farben des INCANTATA-Univerums und lege gleich los.
              </p>
              <a class="inline-block px-4 py-2 mt-6 text-sm font-medium text-black uppercase bg-white rounded shadow-lg" href="#what-is-incantata">mehr erfahren</a>
            </div>
          </div>
          <div class="mt-10 sm:mt-0">
            <deck-builder-preview></deck-builder-preview>
          </div>
        </div>

        <div class="flex flex-wrap justify-around pb-20 -mx-6 lg:justify-between">
          <deck-teaser v-for="deck in presets" :key="deck.id" :deck="deck"></deck-teaser>
        </div>

      </div>

    </div>



    <a name="what-is-incantata"></a>
    <div class="p-10 mx-auto text-xl max-w-screen-xl leading-8">
      <h2 class="pb-4 text-2xl font-bold text-white">Was ist Incantata?</h2>


      <div class="text-gray-300 richtext" style="column-width: 20em;">
        <p>
        Wir haben das mysteriöse Projekt <a class="underline" href="http://127.0.0.1:43110/13VDeMgRgGf73mHsMrrorXkq4fhUKfBvPz/">INCANTATA im ZeroNet</a> gefunden und waren überrascht und begeistert:
        In dem Sammelkartenspiel nutzen zwei bis fünf Spieler in einem magischen
        Kräftemessen die Energie ihrer Zauberbuch-Karten. 
        </p>
        <p>
        Sie beschwören
        Kreaturen und Effekte, die Ihre eigene Macht stärken, um so am Ende über
        die Kräfte des Gegners zu triumphieren.
        </p>
        <p>
        Jeder Spieler wählt dafür zu Beginn mit seinem Deck eine Kartenkonstellation, von denen jede
        unterschiedliche Stärken besitzt. Für welche Philosophie wirst Du Dich
        entscheiden?
        </p>

        <p>
        Für die zahllosen Kreaturenhorden der Waldesbibliothek? Für
        das zerstörische Feuer der Roten Standarte? Oder wirst Du versuchen,
        mittels der Fluxon Bibliothek, Deinen Gegner unter Kontrolle zu bringen?
        </p>

        <p>
        Egal, wie Du Dich entscheidest: sei Dir nicht zu sicher, denn jedes
        Spiel verläuft anders und nur Deine taktischen Entscheidungen werden am
        Ende über Sieg und Niederlage entscheiden. 
        </p>

        <p>
        Lerne die taktischen Tricks
        der unterschiedlichen Deckphilosophien kennen und wenn Du diese
        beherrschst, dann entwickle Deine eigenen Strategien, um eine
        weitere Sicht auf die Welt zu beschwören. Eine Welt, in der Incantata den
        Weg zum Experimentieren, Taktieren und Spielen bereitet.
        </p>
        
        <p>
        Die Kartenvorlagen sind 100% kostenlos verfügbar (Creative Common License).
        Aufgrund des gemeinnützigen Charakters ist dieses Sammelkartenspiel ausgewogen und braucht z.B. nicht, wie bei anderen Sammelkarten, unfaire Karten monetarisieren.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import presets from '@/helpers/presets'
import DeckTeaser from '@/components/DeckTeaser'
import DeckBuilderPreview from '@/components/DeckBuilderPreview'

export default {
  components: {
    DeckTeaser,
    DeckBuilderPreview
  },
  data() {
    return {
      presets: presets
    }
  }
}
</script>
