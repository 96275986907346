<template>
  <div class="max-w-lg mx-auto mt-24 text-center">
    <h1 class="mb-10 text-6xl font-bold">Fehler 404</h1>
    <p>
      Die von Dir gesuchte Seite kann nicht gefunden werden.
    </p>
  </div>
</template>

<script>

export default {
}
</script>
