<template>
  <div class="rounded-full"
       :class="styles"
       v-text="count"
       v-if="count > 0">
  </div>
</template>

<script>
export default {
  props: {
    count: { type: Number },
    size: { type: String, default: 'px-1 text-xs' },
    color: { type: String, default: 'bg-red-700 text-white' }
  },
  computed: {
    styles() {
      return `${this.size} ${this.color}`
    }
  }
}
</script>
