import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home'
import Deck from '@/views/Deck'
import Rules from '@/views/Rules'
import Checkout from '@/views/Checkout'
import NotFound from '@/views/404'

import presets from '@/helpers/presets'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'home', component: Home, meta: { title: 'Home' } },
    { path: '/preset/:id', name: 'preset', component: Deck },
    { path: '/deck', name: 'deck', component: Deck },
    { path: '/rules', name: 'rules', component: Rules, meta: { title: 'Regeln' } },
    { path: '/checkout', name: 'checkout', component: Checkout, meta: { title: 'Kasse' } },
    { path: '*', component: NotFound, meta: { title: 'Not Found (404)' } }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  let title = ''
  // get page title from preset
  if (to.name == 'preset') {
    var preset = presets.find( x => x.id == to.params.id )
    if (preset) title = preset.name
  } else {
    title = to.meta && to.meta.title ?  to.meta.title : '';
  }
  if (title) title = '- ' + title
  document.title = `INCANTATA ${title}`
  next()
})

export default router
