<template>
  <div id="app" class="">

    <div class="bg-gray-900">
      <div class="flex flex-wrap items-center justify-between px-4 py-4 sm:px-6 lg:px-8 xl:px-12">
        <div class="flex items-center">
          <router-link to="/">
            <img src="/img/logo-start.png" class="w-36 sm:w-48">
          </router-link>
          <div class="relative items-center hidden ml-4 text-sm text-gray-400 sm:flex">
            <router-link to="/">Home</router-link>
            <router-link to="/rules" class="pl-4">Regeln</router-link>
            <select-preset-decks></select-preset-decks>
          </div>
        </div>
        <!-- right -->
        <div class="relative ml-4">
          <cart-button></cart-button>
        </div>
      </div>
    </div>

    <router-view />

  </div>
</template>

<script>

import CartButton from '@/components/CartButton.vue'
import SelectPresetDecks from '@/components/SelectPresetDecks.vue'

export default {
  name: 'App',
  components: {
    CartButton,
    SelectPresetDecks,
  }
}
</script>
