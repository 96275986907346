<template>
  <div class="relative mx-auto max-w-card" style="min-width:10em;min-height:15em" :title="card.quantity + ' x &quot;' + card.title + '&quot;  ( ' + card.card_id + '  )'">
    <h2 v-text="card.title" class="absolute z-0 p-5 text-white"></h2>
    <div class="absolute right-0 z-20 -mt-2">
      <badge v-show="card.quantity" :count="card.quantity" size="px-3 text-base" title="Anzahl Karten in deinem Deck"></badge>
    </div>

    <!-- <div class="absolute top-0 left-0 flex items-center justify-between w-20 px-2 py-1 ml-6 text-gray-600 bg-gray-200 rounded rounded-b-none" -->
    <!--      x-show="currentDeck.editable"> -->
    <!--   <button type="button" title="Karte zum Deck hinzufügen"> -->
    <!--     <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg> -->
    <!--   </button> -->
    <!--   <button type="button" title="Karte aus dem Deck entfernen"> -->
    <!--     <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"></path></svg> -->
    <!--   </button> -->
    <!-- </div> -->

    <picture>
      <source class="relative" loading="lazy" :data-srcset="`https://cards.incantata.de/cdn/avif_hq/card_${card.card_id}.avif`" type="image/avif" :srcset="`https://cards.incantata.de/cdn/avif_hq/card_${card.card_id}.avif`">
      <img class="relative rounded-2xl" loading="lazy" :src="`https://cards.incantata.de/cdn/hq/card_${card.card_id}.jpg`" :data-src="`https://cards.incantata.de/cdn/hq/card_${card.card_id}.jpg`" :title="card.quantity + ' x &quot;' + card.title + '&quot;  ( ' + card.card_id + '  )'">
    </picture>
  </div>
</template>

<script>
import Badge from '@/components/Badge.vue'

export default {
  components: {
    Badge
  },
  props: ['card']
}
</script>
